import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeaderTop from "../components/HeaderTop"
import HeaderColor from "../components/HeaderColor"
import RelatedBlog from "./related-blog"
import Footer from "../components/Footer"
import options from "../../config/options"
import { Row, Col } from "antd"
import CallToAction from "../components/CallToAction"
import { CalendarOutlined } from "@ant-design/icons"
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
} from "react-share"
import styled from "@emotion/styled"

const PageWrapper = styled.div`
  margin: 80px 0 60px 0;
  .contentNewWrapper {
    max-width: 1000px;
    margin: 0 auto;
  }
  .contentWrapper {
    max-width: 800px;
    margin: 0 auto;
  }
  h1.title {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 26px;
    color: #323232;
    margin-bottom: 20px;
  }
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    &.bottom {
      margin-top: 50px;
    }
    .itemLeft {
      .date {
        font-family: "Prompt", sans-serif;
        font-weight: 300;
        font-size: 16px;
        color: #848383;
        margin-bottom: 0;
        span {
          margin-right: 10px;
        }
      }
      .share {
        font-family: "Prompt", sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: #323232;
        margin-bottom: 0;
      }
    }
    .itemRight {
      .socials {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        li {
          margin-left: 10px;
          svg {
            border-radius: 100%;
            border: 2px solid #3b4453;
            circle {
              fill: transparent;
            }
            path {
              fill: #323232;
            }
          }
        }
      }
    }
  }
  .featuredWrapper {
    margin: 30px 0;
  }
  .htmlContentWrapper {
    padding: 10px 0 10px 0;
    font-family: "Prompt", sans-serif;
    color: #848383;
    h2,
    h3 {
      font-weight: 500;
      color: #323232;
    }
    h2 {
      font-size: 26px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    b,
    strong {
      font-family: "Prompt", sans-serif;
      font-weight: 600;
    }
    p {
      font-family: "Prompt", sans-serif;
      font-weight: 300;
      font-size: 16px;
      color: #848383;
    }
    table {
      table-layout: fixed;
      max-width: 100%;
      width: 100%;
    }
  }
  .imageContentWrapper {
    margin: 35px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin-bottom: 0;
      margin-right: 16px;
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    table {
      table-layout: fixed;
      max-width: 100%;
      width: 100%;
      thead {
        display: none;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    h1.title {
      font-size: 32px;
    }
    .htmlContentWrapper {
      h2 {
        font-size: 32px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    h1.title {
      font-size: 40px;
    }
    .htmlContentWrapper {
      h2 {
        font-size: 40px;
      }
    }
  }
  @media only screen and (max-width: 320px) {
    .headerWrapper.top {
      flex-wrap: wrap;
      .itemLeft {
        width: 100%;
      }
      .itemRight {
        width: 100%;
        margin-top: 10px;
        .socials {
          li {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }
    }
  }
`

const BlogDetails = ({ data }) => {
  const { node } = data.allContentfulBlogPost.edges[0]
  const { siteUrl, defaultTitle } = data.site.siteMetadata
  const shareUrl = siteUrl + `/blog/` + node.slug

  return (
    <Layout>
      <SEO
        title={node.title + " | " + defaultTitle}
        description={node.seoDescription}
        banner={node.featured ? node.featured.fluid.src : ""}
        article
        pathname={shareUrl}
      />
      <HeaderTop text={options.headerTop} />
      <HeaderColor />
      <PageWrapper>
        <div className="gb-container">
          <div className="contentWrapper">
            <Row>
              <Col span={24}>
                <h1
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: node.title + " | " + defaultTitle,
                  }}
                ></h1>
              </Col>
              <Col span={24}>
                <div className="headerWrapper top">
                  <div className="itemLeft">
                    <p className="date">
                      <CalendarOutlined style={{ color: "#EB3658" }} />
                      {node.createdAt}
                    </p>
                  </div>
                  <div className="itemRight">
                    <ul className="socials">
                      <li>
                        <LineShareButton url={shareUrl}>
                          <LineIcon size={24} round={true} />
                        </LineShareButton>
                      </li>
                      <li>
                        <FacebookShareButton url={shareUrl}>
                          <FacebookIcon size={24} round={true} />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton url={shareUrl}>
                          <TwitterIcon size={24} round={true} />
                        </TwitterShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="contentNewWrapper">
            <Row>
              <Col span={24}>
                <div className="featuredWrapper">
                  <Img
                    loading="eager"
                    fadeIn={false}
                    fluid={node.featured.fluid}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="contentWrapper">
            <Row>
              {node.contentTop && node.contentTop.contentTop.length > 6 ? (
                <Col span={24}>
                  <div className="htmlContentWrapper">
                    <ReactMarkdown
                      plugins={[gfm]}
                      children={node.contentTop.contentTop}
                      allowDangerousHtml={true}
                    />
                  </div>
                </Col>
              ) : null}
            </Row>
          </div>
          {node.contentFull && node.contentFull.contentFull.length > 6 ? (
            <div className="contentNewWrapper">
              <Row>
                <Col span={24}>
                  <div className="imageContentWrapper">
                    <ReactMarkdown
                      plugins={[gfm]}
                      children={node.contentFull.contentFull}
                      allowDangerousHtml={true}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ) : null}
          <div className="contentWrapper">
            <Row>
              {node.contentBottom &&
              node.contentBottom.contentBottom.length > 6 ? (
                <Col span={24}>
                  <div className="htmlContentWrapper">
                    <ReactMarkdown
                      plugins={[gfm]}
                      children={node.contentBottom.contentBottom}
                      allowDangerousHtml={true}
                    />
                  </div>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col span={24}>
                <div className="headerWrapper bottom">
                  <div className="itemLeft">
                    <p className="share">Share :</p>
                  </div>
                  <div className="itemRight">
                    <ul className="socials">
                      <li>
                        <LineShareButton url={shareUrl}>
                          <LineIcon size={24} round={true} />
                        </LineShareButton>
                      </li>
                      <li>
                        <FacebookShareButton url={shareUrl}>
                          <FacebookIcon size={24} round={true} />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton url={shareUrl}>
                          <TwitterIcon size={24} round={true} />
                        </TwitterShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </PageWrapper>
      <RelatedBlog slug={node.slug} defaultTitle={defaultTitle} />
      <Footer />
      <CallToAction />
    </Layout>
  )
}

export default BlogDetails

export const query = graphql`
  query blogSingleQuery($slug: String!) {
    allContentfulBlogPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          seoDescription
          slug
          title
          contentTop {
            contentTop
          }
          contentFull {
            contentFull
          }
          contentBottom {
            contentBottom
          }
          createdAt(formatString: "MMMM DD, YYYY")
          featured {
            fluid(maxWidth: 1400) {
              ...GatsbyContentfulFluid_withWebp
              src
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        defaultTitle
      }
    }
  }
`
