import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Row, Col } from "antd"
import Img from "gatsby-image"
import { CalendarOutlined } from "@ant-design/icons"
import styled from "@emotion/styled"

const PageWrapper = styled.div`
  padding-bottom: 80px;
  h2 {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 26px;
    color: #323232;
    text-align: center;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 1200px) {
    h2 {
      font-size: 40px;
    }
  }
`
const CardWrapper = styled.div`
  background: #f5f5f5;
  height: 100%;
  min-height: 100%;
  .contentWrapper {
    padding: 25px;
    h3 {
      font-family: "Prompt", sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #323232;
      margin-bottom: 20px;
    }
    p {
      font-family: "Prompt", sans-serif;
      font-weight: 300;
      font-size: 16px;
      color: #848383;
      margin-bottom: 0;
      span {
        margin-right: 10px;
      }
    }
  }
`

const RelatedBlog = props => {
  const { blogPosts } = useStaticQuery(
    graphql`
      query blogRelatedQuery {
        blogPosts: allContentfulBlogPost(
          limit: 4
          filter: { categories: { elemMatch: { title: { eq: "Knowledge" } } } }
          sort: { order: DESC, fields: createdAt }
        ) {
          edges {
            node {
              slug
              title
              createdAt(formatString: "MMMM DD, YYYY")
              featured {
                fluid(maxWidth: 580) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  let count = 0
  const posts = blogPosts.edges.filter(({ node }) => {
    return node.slug !== props.slug && count++ < 3
  })

  return posts && posts.length > 0 ? (
    <PageWrapper>
      <div className="gb-container">
        <Row>
          <Col span={24}>
            <h2>ความรู้โซล่าเซลล์</h2>
          </Col>
        </Row>
        <Row gutter={[16, 32]}>
          {posts.map(({ node }) => {
            return (
              <Col key={node.slug} xs={24} sm={12} lg={8}>
                <CardWrapper>
                  <div className="featuredWrapper">
                    <Link to={`/blog/` + node.slug}>
                      <Img
                        loading="eager"
                        fadeIn={false}
                        fluid={node.featured.fluid}
                      />
                    </Link>
                  </div>
                  <div className="contentWrapper">
                    <Link to={`/blog/` + node.slug}>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: node.title + " | " + props.defaultTitle,
                        }}
                      ></h3>
                    </Link>
                    <p>
                      <CalendarOutlined style={{ color: "#EB3658" }} />
                      {node.createdAt}
                    </p>
                  </div>
                </CardWrapper>
              </Col>
            )
          })}
        </Row>
      </div>
    </PageWrapper>
  ) : null
}

export default RelatedBlog
